<template style="padding: 50px;">
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(group, i) in groups"
      :key="i"
    >
      <v-expansion-panel-header>
        {{ group.name}}
      </v-expansion-panel-header>
      <v-expansion-panel-content style="background-color: #CCCCCC;">
        <v-layout 
          wrap
          v-for="(task, j) in group.tasks"
          :key="`${i}-${j}`"
          style="margin: 5px 0;"
        >
          <v-flex xs2>
            <v-btn
              block
              @click="handleOpen(task.link)"
            >
              {{ task.name}}
            </v-btn>
          </v-flex>
          <v-flex xs10 style="padding: 5px 20px;">
            <small>{{task.link}}</small>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  computed: {
    groups () {
      return [
        {
          name: 'Dviewcontent OLD',
          tasks: [
            {
              name: 'RSS',
              link: 'https://rss-dviewcontent-api.dviewcontent.com/cron/xml/',
            },
          ]
        },
        {
          name: 'Dviewcontent CM (solo Cl�nica planas)',
          tasks: [
            {
              name: 'RSS',
              link: 'https://cm-dviewcontent-api.dviewcontent.com/public/cron/ac181b77-bf19-4a60-ab8e-b2c28d2b1d05',
            },
          ]
        },
        {
          name: 'Nuryana', 
          tasks: [
            {
              name: 'Menus',
              link: 'https://cron-api.dviewcontent.com/public/cron/menus-to-image/08fc6260-6abb-11ed-ab87-b3e2a21846f0',
            },
            {
              name: 'Cumplea�os completo',
              link: 'https://cron-api.dviewcontent.com/public/cron/birthday-to-image/08fc6260-6abb-11ed-ab87-b3e2a21846f0',
            },
            {
              name: 'Cumplea�os bachillerato',
              link: 'https://cron-api.dviewcontent.com/public/cron/birthday-to-image/37a990b0-ad28-11ed-80f7-fb1557cff431',
            },
          ],
        },
        {
          name: 'Caser',
          tasks: [
            {
              name: 'Actividades a image',
              link: 'https://cron-api.dviewcontent.com/public/cron/activity-to-imagev2/',
            },
            {
              name: 'WeeklyMenu',
              link: 'https://cm-dviewcontent-api.dviewcontent.com/v1/public/weekly-menu/cron',
            },
          ]
        },
        {
          name: 'Mutua madrile�a',
          tasks: [
            {
              name: 'Noticias',
              link: 'https://cm-dviewcontent-api.dviewcontent.com/public/cron/971fc7ce-f92b-4c17-9bfc-40df4dd1c0c3',
            },
            {
              name: 'Tr�fico',
              link: 'https://cm-dviewcontent-api.dviewcontent.com/public/cron/38f9b53b-21b6-46c6-bf67-98131b903633',
            },
          ]
        },
        {
          name: 'Aena',
          tasks: [
            {
              name: 'Aena',
              link: 'https://cm-dviewcontent-api.dviewcontent.com/v1/public/aena/departures/cron',
            },
          ]
        },
        {
          name: 'Bolsa',
          tasks: [
            {
              name: 'Bolsa',
              link: 'https://widgets.dviewcontent.com/bolsa/api/cron.php',
            },
          ]
        },
      ]
    },
  },
  methods: {
    handleOpen (v) {
      window.open(v, '_blank').focus()
    },
  },
}
</script>

